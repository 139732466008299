import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import moment from "moment"
import UploadSlip from '@/components/widgets/widget-upload-slip'
import PreviewSlip from '@/components/widgets/widget-invoice-slips'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    Loading,
    DatePicker,
    UploadSlip,
    PreviewSlip,
    Paginate
  },
  data: function() {
    return {
      role: "",
      branch: "",
      keyword_document_no: "",
      keyword_status: null,
      keyword_start: null,
      keyword_end: null,
      keyword_branch: null,
      busy: false,
      apiStatus: 'ไม่พบข้อมูล',
      totalItem: 0,
      loading: false, 
      state: false,
      getPaid: {
        Id: null,
        state: false
      },
      preview: {
        uuid: "",
        data: [],
        state: false,
        status: 0,
        role: "",
        branch: "",
      },
      status: null,
      doc: {
        start: null,
        end: null
      },
      debtStatus: [
        { text: 'รอการชำระ', value: '1' },
        { text: 'ชำระแล้ว รอการตรวจสอบ', value: '2' },
        { text: 'ยกเลิก', value: '3' },
        { text: 'ตรวจสอบไม่ผ่าน กรุณาอัพโหลดใหม่', value: '4' },
        { text: 'สำเร็จ', value: '5' },
        { text: 'รอ Confirm ราคา', value: '6' },
      ],
      sortBy: 'name',
      perPage: 8,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'po.document_no', 
          label: 'เลขที่ PO',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'created_at', 
          label: 'วันที่ของ IV',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'document_no', 
          label: 'เลขที่ IV',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'branch_name', 
          label: 'ชื่อลูกหนี้',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'po.license_plate', 
          label: 'คันที่รับม้วน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'status', 
          label: 'สถานะการชำระเงิน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: []
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    handleGetPaidEvent(e) {
      this.getPaid.state = false
      if (e.status) {
        this.onInitData(this.currentPage)
      }
    },
    handleShowSlipEvent(e) {
      this.preview.state = false
      if (e.status) {
        this.onInitData(this.currentPage)
      }
    },
    onShowSlip(data, uuid, status, role, branch) {
      this.preview.uuid = uuid
      this.preview.data = data
      this.preview.status = status
      this.preview.role = role
      this.preview.branch = branch
      this.preview.state = !this.preview.state
    },
    onGetPaid(id) {
      this.getPaid.Id = id
      this.getPaid.state = !this.getPaid.state
    },
    oncreate() {
      this.$router.push('/invoice/added')
    },
    onModifyInvoice(item) {
      this.$router.push("/invoice/edit/" + item.uuid)
    },
    async onInitData(currentPage) {
      this.currentPage = currentPage
      this.busy = true
      this.apiStatus = 'กำลังค้นหาข้อมูล..'
      // this.items = []
      try {
        const page = currentPage ? currentPage : 1
        const param = {
          page: page,
          keyword_document_no: this.keyword_document_no,
          keyword_status: this.keyword_status,
          keyword_start: this.keyword_start ? moment(this.keyword_start).format('YYYY-MM-DD') : null,
          keyword_end: this.keyword_end ? moment(this.keyword_end).format('YYYY-MM-DD') : null,
          keyword_branch: this.keyword_branch ? this.keyword_branch : '',
        }
        const result = await this.$store.dispatch('Documents/getAllInvoiceDocument', param, { root: true })
        this.busy = false

        this.apiStatus = 'ไม่พบข้อมูล'
        if (result) {
          this.currentData = result.data;
          this.items = result.data.data
          this.totalItem = result.data.total
        } else {
          this.items = []
          this.totalItem = 0
        }        
      } catch (error) {
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
        this.busy = false
        return []
      }
    },
    onConfirmInvoice(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-normal text-base py-2.5 text-left">คุณต้องการจะยืนยันราคาตามใบเสนอราคานี้หรือไม่?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.realConfirm(id)
        }
      })
    },
    async realConfirm(id){
      try {
        await this.$axios.post(this.$host + "/api/v1/confirmprice/" + id)

        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            '<span class="text-base text-left text-gray-500">ดำเนินการเรียบร้อยแล้ว</span>'+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            cancelButtonColor: '#dc4a38',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: "ตกลง",
            showCancelButton: false,
            showCloseButton: true,
        }).then(async confirm => {
          if(confirm.value){
            this.onInitData(this.currentPage)
          }
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      } 
    },
    async getBranches() {
      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.branches = result.data
    },
  },
  mounted() {
    this.getBranches()
    this.onInitData(this.currentPage)
    this.getRole()
  }
}
